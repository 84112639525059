<script setup lang="ts">
	import { useUserStore } from "~/store/userStore";

  // import 'bootstrap/dist/js/bootstrap';
  // import 'bootstrap/dist/css/bootstrap.css';

  import moment from 'moment';
  import { useTocStore } from "~/store/tocStore";

	const userStore = useUserStore();
  const tocStore = useTocStore();

  const router = useRouter();
  const route = useRoute();

  const user = computed(() => {
    return userStore.loginUserInfo;
  })

  const tocTitle = computed(() => userStore.loginUserInfo?.personalTitle);
  const logoImg = computed(async() => {
    if(userStore.loginUserInfo?.personalLogoPath) await makeLogoImg(userStore.loginUserInfo?.personalLogoPath);

    return userStore.loginUserInfo?.personalLogoPath;
  });

  const logoImageFile = ref();
  

	function dropdown() {
		var v = document.querySelector(".dropdown-content") as HTMLElement;
    if(v.classList.contains('show')){
      v.classList.remove('show');
    } else {
      v.classList.add('show');
    }
	}

  const realTime = ref(moment().format('YYYY-MM-DD HH:mm:ss'));

  onMounted(() => {
    setInterval(() => {
      realTime.value = moment().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)

    document.body.addEventListener('click' ,dropDownEventFn, false)
  })

  function dropDownEventFn(e: Event){
    const dropdownContent = document.getElementsByClassName('dropdown-content')[0];

    if((e.target as HTMLElement).id === 'dropdownMenuButton'){
      return;
    }

    if(!dropdownContent || !dropdownContent.classList){
      return;
    }

    if(dropdownContent.classList.contains('show')){
      dropdownContent.classList.remove('show')
    }
  }

  function logout(){
    userStore.logout();
    router.replace('/toc/user/login')
  }

  function clickLogo(){
    if(route.fullPath.includes('login')){
      router.replace('/toc/user/login')
    } else {
      router.push('/toc/monitoring/newDashboard')
    }
  }

  async function makeLogoImg(url: string | null | undefined){

    if(url){
      let file = await tocStore.getUserTocSettingImage(url);
      if(file) logoImageFile.value = window.URL.createObjectURL(file);
    }
  }

  onUnmounted(() => {
    window.URL.revokeObjectURL(logoImageFile.value);
  })

</script>

<template>
	<header>
		<div class="logobox" @click="clickLogo" :style="{cursor: 'pointer'}">
			<img v-if="logoImg && logoImageFile" :src="`${logoImageFile}`" :style="{width: '80px', height: '15px'}"/>
			<img v-else src="@/assets/images/toc/logo-header.svg" :style="{width: '80px', height: '15px'}"/>
			<p v-if="tocTitle">{{ tocTitle }}</p>
			<p v-else>통합관제시스템</p>
		</div>
		<!-- <h1 v-if="user"><span>{{ '업체명' }}</span> 대시보드</h1> -->
		<div class="userbox" v-if="user && !route.fullPath.includes('login')">
			<div class="day" :style="{marginRight: '15px'}">{{ realTime }}</div>
			<!-- <div class="qna"><img src="@/assets/images/toc/qnaicon.svg" /><span class="error"></span></div> -->
			<!-- <div class="alarm"><img src="@/assets/images/toc/alarm.svg" /><span class="error"></span></div> -->
			<!-- <div><img src="@/assets/images/toc/alarm.svg" /><span class="error"></span></div> -->
			<div class="user">
				<img src="@/assets/images/toc/user.svg" />
				<div class="dropdown">
					<div class="dropbtn" id="dropdownMenuButton" @click="dropdown">{{ user.userName }}</div>
					<ul class="dropdown-content dropdown-menu" >
						<!-- <li>회원등록관리</li> -->
						<!-- <li>고객센터관리</li> -->
						<li @click="logout">로그아웃</li>
          </ul>
				</div>
			</div>
		</div>
	</header>
</template>

<style lang="scss" scoped>

</style>